import React, { useRef, useState, useEffect, useCallback } from "react"
import { graphql, useStaticQuery } from "gatsby"
import PageSection from "../../components/page-section"
import { ParallaxBanner } from "react-scroll-parallax"
// @ts-ignore
import Logo from "../../assets/logo-white.inline.svg"

import "./title-section.scss"

const TitleSection = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage1: file(relativePath: { eq: "header-slideshow/01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            src
          }
        }
      }
      placeholderImage2: file(relativePath: { eq: "header-slideshow/02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            src
          }
        }
      }
      placeholderImage3: file(relativePath: { eq: "header-slideshow/03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            src
          }
        }
      }
      placeholderImage4: file(relativePath: { eq: "header-slideshow/04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            src
          }
        }
      }
    }
  `)

  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  const bannerImage1 = useRef(null)
  const bannerImage2 = useRef(null)
  const bannerImage3 = useRef(null)
  const bannerImage4 = useRef(null)

  const imageRefs = [bannerImage1, bannerImage2, bannerImage3, bannerImage4]

  const incrementImagePointer = useCallback(() => {
    setCurrentImageIndex((currentImageIndex + 1) % imageRefs.length)
  }, [setCurrentImageIndex, currentImageIndex, imageRefs.length])

  useEffect(() => {
    const interval = setInterval(incrementImagePointer, 5000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incrementImagePointer])

  useEffect(() => {
    imageRefs.forEach(ref => {
      // @ts-ignore
      ref.current.classList.remove("active")
    })
    // @ts-ignore
    imageRefs[currentImageIndex].current.classList.add("active")
  }, [currentImageIndex, imageRefs])

  return (
    <PageSection label="home" noTopPadding>
      <ParallaxBanner
        className="my-banner"
        layers={[
          {
            image: data.placeholderImage1.childImageSharp.fluid.src,
            amount: 0.3,
            children: null,
            // @ts-ignore
            props: { className: "banner-image", ref: bannerImage1 },
          },
          {
            image: data.placeholderImage2.childImageSharp.fluid.src,
            amount: 0.3,
            children: null,
            // @ts-ignore
            props: {
              className: "banner-image",
              ref: bannerImage2,
            },
          },
          {
            image: data.placeholderImage3.childImageSharp.fluid.src,
            amount: 0.3,
            children: null,
            // @ts-ignore
            props: {
              className: "banner-image",
              ref: bannerImage3,
            },
          },

          {
            image: data.placeholderImage4.childImageSharp.fluid.src,
            amount: 0.3,
            children: null,
            // @ts-ignore
            props: {
              className: "banner-image active",
              ref: bannerImage4,
            },
          },
          {
            amount: -1,
            children: (
              <div
                className="parallax-layer-containerx"
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div className="logo-containerx">
                  <Logo
                    className="logo"
                    style={{ width: "80vw", maxHeight: "350px" }}
                  />
                </div>
              </div>
            ),
          },
        ]}
        style={{ height: "100vh" }}
      />
    </PageSection>
  )
}

export default TitleSection
